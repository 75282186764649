export const SKILLS = [
{

    title: "Game Development",
    icon: "../../../images/frontend-icon.png",
    skills: [
        {skill: "Unity Engine", percentage: "75%"},
        {skill: "C# Programming", percentage: "75%"},
        {skill: "2D/3D Game Development", percentage: "50%"},
        {skill: "OOP", percentage: "65%"},
        {skill: "Debugging & Troubleshooting", percentage: "65%"},

    ],

},
{

    title: "Fullstack",
    icon: "../../../images/backend-icon.png",
    skills: [
        {skill: "JavaScript", percentage: "85%"},
        {skill: "React.js", percentage: "70%"},
        {skill: "HTML5", percentage: "85%"},
        {skill: "CSS3 (Sass/SCSS)", percentage: "80%"},
        {skill: "Node.js", percentage: "65%"},
        {skill: "Express.js", percentage: "50%"},
        {skill: "Axios", percentage: "70%"},
    ],
},


{

    title: "Databases",
    icon: "../../images/database-icon.png",
    skills: [
        {skill: "Sql (PostreSql)", percentage: "70%"},
        {skill: "NoSql (MongoDB)", percentage: "50%"}
    ],
},

{

    title: "Tools",
    icon: "../../../images/tools-icon.png",
    skills: [
        {skill: "Git & GitHub", percentage: "60%"},
        {skill: "Visual Studio Code", percentage: "80%"},

    ],
},
]


export const PROJECTS = [


    {
    
        title: "Simon",
        icon: "../../../images/simon2.png",
        description: [
            "My version of the classic game that tests players' memory as they follow thesequence of colors and sounds. Developed with React and Typescript and utilizing the Axios library to store and manage user high scores.",
        ],
        link: "https://simon-scfa.onrender.com/"
    },
    {
    
        title: "Super Mario",
        icon: "../../../images/superMario.png",
        description: [
            "Building a web-based game as homage to the original game. Developed in JavaScript with Canvas based on OOP principles.",
        ],
        link: "https://liorgeni.github.io/super-mario"

    },


    {
    
        title: "The Memes Generator",
        icon: "../../../images/memegen.png",
        description: [
            "Unleash your creativity with the Memes Generator, a playful JavaScript game ingeniously crafted using the power of Canvas. Design and create custom memes with ease. The user-friendly interface allows for dynamic features possibilities - enabling users to style the text share their meme."
        ],
        link: "https://liorgeni.github.io/The-mems-generator/"

    },
    
    {
    
        title: "Gixerr",
        icon: "../../../images/gigxerr.png",
        description: [
            "React and Node.js-based end-to-end (E2E) application similar to 'Fiverr', leveraging MongoDB to store and manipulate data, providing users with real-time statistics.",
        ],
        link: "https://gigxerr.onrender.com"

    },

    {
    
        title: "Appsus",
        icon: "../../../images/appsus.png",
        description: [
            "A web application written in ReactJs. This app combines the functionalities of Gmail and Google Keep. My role was to create an intuitive and user-friendly application that allows users to write notes, edit them, add video, and location (using google API).",
        ],
        link: "https://liorgeni.github.io/Appsus/"

    },

    {
    
        title: "Mines Sweeper",
        icon: "../../../images/mines-sweeper.png",
        description: [
            "Dive into the world of Minesweeper, a thrilling JavaScript game skillfully crafted with a matrix-based design. Offering various difficulty levels, players navigate minefields with caution, strategically uncovering tiles to reveal hidden mines.",
        ],
        link: "https://liorgeni.github.io/mineSweeper/"

    },
    {
    
        title: "PAC-MAN",
        icon: "../../../images/pacman.png",
        description: [
            "I crafted this JavaScript Pacman game using a matrix-based approach. Navigate the maze, eat pellets, dodge ghosts, and experience the classic arcade fun!",
        ],
        link: "https://liorgeni.github.io/PacMan/"

    },

    {
    
        title: "Touch The Numbers!",
        icon: "../../../images/touchnums.png",
        description: [
            "Embark on a speedy challenge with 'Touch the Number,' a captivating game coded in JavaScript using a matrix approach. Test your reflexes as you race against the clock, aiming to touch the correct numbers on the grid. Challenge yourself with different difficulty levels.",
        ],
        link: "https://liorgeni.github.io/touchNums/"

    },

    ]