import React from 'react'
import {Link} from 'react-scroll'
import "./MobileNav.css"



const MobileNav = ({isOpen, toggleMenu}) => {
  return (
    <>
    <div 
    className={`mobile-menu ${isOpen ? "active" : ""}`} onClick={toggleMenu}
    >
<div className='mobile-menu-container'>
<img className='logo' src="./assets/images/logo.svg" alt="" />

<ul>
        <li>
            <Link to="hero" spy={true} smooth={true} offset={-50} duration={500} className='menu-item'>Home</Link>
        </li>
        <li>
        <Link to="game-content" spy={true} smooth={true} offset={-150} duration={500} className='menu-item'>Portfolio</Link>

        </li>
        <li>
        <Link to="skills" spy={true} smooth={true} offset={-670} duration={500} className='menu-item'>Skills</Link>
        </li>
        <button className='contact-btn'><Link to="app-footer" spy={true} smooth={true} offset={50} duration={500} >Contact Me</Link></button>

    </ul>
    </div>

</div>
    </>
  )
}

export default MobileNav