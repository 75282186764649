import React from 'react';
import './App.css'
import Navbar  from './cmps/Navbar/NavBar/Navbar';
import Hero from './cmps/Navbar/Hero/Hero';
import Skills from './cmps/Skills/Skills';
import Portfolio from './cmps/Portfolio/Portfolio';
import ContactMe from './cmps/Contact/ContactMe';

const App = () => {
  
console.log('Welcome to my site! Have a great day =]')

  return (
<>
<Navbar />

<div className='container'>
  < Hero />
  <Portfolio />
  < Skills />
</div>
  <ContactMe /> 
  </>
  );
}

export default App;
