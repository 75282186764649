import React, { useState } from 'react'
import {Link} from 'react-scroll'
import './Navbar.css'
import MobileNav from './MobileNav/MobileNav';



const Navbar = () => {

const [openMenu, setOpenMenu] = useState(false);

const toggleMenu = () => {
    setOpenMenu(!openMenu)
}

  return (
<>
<MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

<nav className='nav-wrapper'>
<div className='nav-content'>
    <img className='logo' src="../../../images/logo3.svg" alt="" />
    <ul>
        <li>
            <Link to="hero" spy={true} smooth={true} offset={-50} duration={500} className='menu-item'>Home</Link>
        </li>
        <li>
        <Link to="game-content" spy={true} smooth={true} offset={-200} duration={500} className='menu-item'>Portfolio</Link>

        </li>
        <li>
        <Link to="skills" spy={true} smooth={true} offset={-250} duration={500} className='menu-item'>Skills</Link>
        </li>
        <button className='contact-btn'><Link to="app-footer" spy={true} smooth={true} offset={50} duration={500} >Contact Me</Link></button>

    </ul>

    <button className='menu-btn' onClick={toggleMenu}>
        <span className={"material-symbols-outlined"} style={{fontSize:"1.8rem"}}>
            {openMenu ? "close" : "menu"}
        </span>
    </button>
</div>

</nav>
</>
  )
}

export default Navbar
