import React from 'react';
import './ContactMe.css';
import facebook from "../../../src/svg/icon-facebook.svg";
import linkedin from "../../../src/svg/icon-linkedin.svg";
import instagram from "../../../src/svg/icon-instagram.svg";
import gitIcon from "../../../src/svg/icon-github.svg";

 const ContactMe = () => {
  return (
    <footer className="app-footer">
      <div className="footer-nav">
        <h5>Contact me</h5>
        <div className="social-links">
          <a
            href="https://www.linkedin.com/in/lior-geni-0897a7229/"
            target="_blank"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
          <a href="https://www.facebook.com/liorgeni/" target="_blank">
            <img src={facebook} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/liorgeni/" target="_blank">
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://github.com/liorgeni/" target="_blank">
            <img src={gitIcon} alt="instagram" />
          </a>
        </div>
        <p className="mail">liorgeni@gmail.com</p>
        <br />
        <p>©2024</p>
      </div>
    </footer>  )
}

export default ContactMe