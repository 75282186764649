import React, { useRef } from 'react'
import './Portfolio.css'
import ProjectCard from './ProjectCard/ProjectCard'
import {PROJECTS} from '../../utils/data'
import Slider from "react-slick"

const Portfolio = () => {

    const sliderRef = useRef()

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const slideRight = ()=> {
sliderRef.current.slickNext()
}
const slideLeft = ()=> {
sliderRef.current.slickPrev()
}


  return (


<section className='portfolio-container'>

<section className='game-container'>
<h5>Portfolio</h5>

<section className='game-content'>
      <p>Check out my Artist page at: <br/> <br/><a className='art-link'  href="https://www.artstation.com/liorgeni9" target="_blank" rel="noopener noreferrer">ArtStation!</a></p>
      <br/>
<p>Or get a glimpse of my Games:</p>
<br/>
<div className="video-wrapper">
        <iframe 

          src="https://streamable.com/e/astkxn?" 
          title="My Game Reel" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
      </div>

      <br/>
      <br/>
      </section>

      </section>

    {/* <h5>Fullstack Portfolio</h5>

    <div className='portfolio-content'>
<div className='arrow-right' onClick={slideRight}>
<span className="material-symbols-outlined">chevron_right</span>
</div>
<div className='arrow-left' onClick={slideLeft}>
<span className="material-symbols-outlined">chevron_left</span>
</div>


        <Slider ref={sliderRef} {...settings}>
{PROJECTS.map((item) => (
<ProjectCard key={item.title} details={item} />
))}


</Slider>
    </div> */}
</section>  )
}


export default Portfolio